import React, { useContext } from 'react'
import { useTranslation,Link} from 'gatsby-plugin-react-i18next'
import InstagramIcon from '@material-ui/icons/Instagram';
import FacebookIcon from '@material-ui/icons/Facebook';
import { Button, Grid, Typography ,Box} from '@material-ui/core'
import { DataContext } from '../../../context/DataContext';

export default function Footer({ isPrincipal = false }) {
    const { t } = useTranslation();
    const {setIndexTab} = useContext(DataContext);
    return (
        <div className='footer'>
            <Grid container alignItems='center' className='footer__main-content'>
                <Grid item xs={12} sm>
                    <Typography className='message'>
                        {isPrincipal ? t('Footer Message Covid') : t('Footer Message Covid Alt')}
                    </Typography>
                </Grid>
                <Grid item xs={12} sm>
                    {!isPrincipal &&<Link to='/covid19-protocol' onClick={()=>setIndexTab(1)}><Button variant="contained" color="default">
                        {t('More Information')}
                    </Button></Link>}
                </Grid>
            </Grid>
            <Box className='social-networks' display={{ xs: 'none', md: 'block' }}>
                <span>
                    <a href={'https://www.instagram.com/waitnrest/'} target="_blank" rel="noreferrer">
                        <InstagramIcon />
                    </a>
                    <a href={'https://www.facebook.com/waitnrest/'} target="_blank" rel="noreferrer">
                        <FacebookIcon />
                    </a>
                </span>
            </Box>
        </div>
    )
}
